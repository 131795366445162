<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinamica Adicionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Empresas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Seleccionar Tipo Busqueda</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="tipo_consulta"
                    :class="
                      $v.tipo_consulta.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option :value="1">Adicionales</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-2">
                <label>N° Solicitud</label>
                <input
                  type="number"
                  id="id"
                  placeholder="N° Solicitud"
                  label="N° Solicitud"
                  v-model="filtros.n_solicitud"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label for="fecha_solicitud">Fecha Solicitud</label>
                <input
                  type="date"
                  id="fecha_solicitud"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.fecha_solicitud"
                />
              </div>

              <div class="form-group col-md-3">
                <label>Sitio</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="sitio"
                  placeholder="Sitios"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.sitios"
                  :filterable="true"
                  @input="getSelectSitio()"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>Empresa Transportadora</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.transportadora_id"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="transportadora_id in listasForms.empresas"
                    :key="transportadora_id.id"
                    :value="transportadora_id.id"
                  >
                    {{ transportadora_id.razon_social }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Tipo de Ruta</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.tipo_ruta"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_ruta in listasForms.tipo_rutas"
                    :key="tipo_ruta.numeracion"
                    :value="tipo_ruta.numeracion"
                  >
                    {{ tipo_ruta.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Estado</label>
                <select
                  id="estado"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.estado"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  v-show="!$v.tipo_consulta.$invalid"
                  class="btn bg-navy btn-sm"
                  @click="generarPivotTable()"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.adicionalesSolicitudes.pivotTable'
                    )
                  "
                >
                  Generar Busqueda
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
export default {
  name: "SolicitudPivotTable",
  components: {
    PivotTableComponent,
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      sitio: {},
      nominacion: {},
      filtros: {
        n_solicitud: null,
        fecha_solicitud: null,
        sitio_id: null,
        transportadora_id: null,
        tipo_ruta: null,
        estado: null,
      },
      tipo_consulta: null,
      listasForms: {
        sitios: [],
        nominaciones: [],
      },
    };
  },
  validations: {
    tipo_consulta: {
      required,
    },
  },
  methods: {
    back() {
      return this.$router.replace("/Hidrocarburos/AdicionalesSolicitudes");
    },

    getTipoRutas() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },

    getEmpresas() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },
    getEstados() {
      axios.get("/api/lista/63").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getSelectSitio() {
      this.filtros.sitio = {};
      this.filtros.sitio_id = null;
      if (this.sitio) {
        this.filtros.sitio = this.sitio;
        this.filtros.sitio_id = this.sitio.id;
      }
    },
    async getSitio() {
      await axios.get("api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/ajusteCarguesSolicitudes/adicionalpivotTable",
        data: { tipo_consulta: this.tipo_consulta, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getTipoRutas();
    await this.getSitio();
    await this.getEmpresas();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
